import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

export enum LogLevel {
  trace,
  debug,
  information,
  warning,
  error,
  critical,
  none,
}

@Injectable()
export class LoggerService {
  constructor() {
    const l: string = environment.logLevel;
    // @ts-ignore
    const level = LogLevel[l];

    this.setLogLevel(level);
  }

  setLogLevel(level: number): void {
    if (Number.isNaN(level)) {
      return;
    }

    this.logTrace = level <= LogLevel.trace ? console.trace : this.logEmpty;
    this.logDebug = level <= LogLevel.debug ? console.debug : this.logEmpty;
    this.logInfo = level <= LogLevel.information ? console.info : this.logEmpty;
    this.logWarning = level <= LogLevel.warning ? console.warn : this.logEmpty;
    this.logError = level <= LogLevel.error ? console.error : this.logEmpty;
    this.logCritical = level <= LogLevel.error ? console.error : this.logEmpty;
  }

  logTrace: <T>(message: string, data: T, codeInitiator?: string) => void = console.trace;

  logDebug: <T>(message: string, data: T, codeInitiator?: string) => void = console.debug;

  logError: <T>(message: string, data: T, codeInitiator?: string) => void = console.error;

  logCritical: <T>(message: string, data: T, codeInitiator?: string) => void = console.error;

  logInfo: <T>(message: string, data: T, codeInitiator?: string) => void = console.info;

  logWarning: <T>(message: string, data: T, codeInitiator?: string) => void = console.warn;

  protected logEmpty: () => void = () => {};
}
