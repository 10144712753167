import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of, retry } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import * as en from '../../../src/assets/i18n/en.json';

export class TranslateLoaderService implements TranslateLoader {
  constructor(private http: HttpClient, private prefix?: string, private suffix?: string) {}

  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      retry(3),
      catchError(() => of(en)),
    );
  }
}
