import { Injectable } from '@angular/core';
import { AppStore } from '../app.store';
import { SignStore } from '../pages/sign/sign.store';
import { DomService } from '@services/dom.service';

export interface IEvent {
  type: EventType;
  action?: string;
  source?: string;
  errorMessage?: string;
  error?: IFidoError;
  metadata?: object;
}

export interface IFidoError {
  name?: string;
  code?: number;
  message?: string;
  errorReason?: string;
  additionalInformation?: object;
}

export enum EventType {
  Click = 'click',
  Track = 'track',
  PageView = 'pageView',
  Error = 'error',
}

export enum CategoryMap {
  register = 'registration',
  login = 'login',
  link = 'link',
  linkonlogin = 'login',
}

@Injectable()
export class EventsService {
  private metadata: object = {};

  constructor(private appStore: AppStore, private signStore: SignStore, private domService: DomService) {}

  async publish(event: IEvent): Promise<void> {
    // @ts-ignore
    const version = window.appVersion;
    const userAgent = window.navigator.userAgent;
    const context = this.signStore.context$.getValue() || this.domService.getChallengeFromUrl();
    const category = CategoryMap[this.signStore.initialChallengeType$.getValue()!];
    const loginId =
      this.signStore.currentStep$.getValue()?.data?.fido2Settings?.userName ||
      this.signStore.currentStep$.getValue()?.data?.loginId;

    const emailProvider = loginId?.includes('@') ? loginId.slice(loginId.indexOf('@') + 1) : null;

    const appName = this.signStore.connectionName$.getValue();
    const applicationOrigin =
      document.referrer !== ''
        ? document.referrer.split('/')[2].replace('www.', '')
        : window.location.host === 'passwordless.ownid.com'
        ? window.location.search.split('%2F')[2]
        : window.location.hostname.split('passwordless.')[1];

    if (event.metadata) {
      this.metadata = {
        ...this.metadata,
        ...event.metadata,
      };
    }

    // @ts-ignore
    this.metadata.origin = this.appStore.isFromDesktop$.getValue() ? 'Desktop' : 'Other';
    // @ts-ignore
    this.metadata.emailProvider = emailProvider;

    fetch(`${this.appStore.backendUrl$.getValue() ?? ''}/events`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...event,
        metadata: this.metadata,
        version,
        component: 'webApp',
        userAgent,
        context,
        category,
        applicationOrigin,
        appName,
        loginId: loginId ? await this.domService.getSha256Base64(loginId) : null,
        sourceTimestamp: Date.now().toString(),
      }),
    });

    return new Promise((resolve) => setTimeout(() => resolve()));
  }
}
