import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  baseURL: '/api/v1/',
  logLevel: "information",
  logType: "backend",
  salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
  serverURL: '',
  maxProfileFieldsCount: 50,
  envName: 'prod',
  translationsUrlPrefix: 'https://i18n.prod.ownid.com/',
  translationsUrlSuffix: '/web-app.json',
  allowMocks: false,
  logRocket: {
    'passwordless.carnival.com': '8aznfr/webapp-carnival',
    'passwordless-www4.nonprod.carnivalcloud.net': '8aznfr/webapp-carnival',
  }
};
