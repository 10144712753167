import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { BaseRepository } from './base-repository';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

export interface Cookie {
  credId?: string;
  loginId?: string;
}

@Injectable()
export class CookiesRepository extends BaseRepository {
  cookiesURL = `${environment.serverURL}/cookies`;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  setFido2Cookies(requesterId: string, credId: string): Observable<void> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const body = {
      requesterId,
      credId,
    };

    return this.post<void>(`${this.cookiesURL}/credid`, body, options).pipe(catchError(() => of(void 0)));
  }

  getCookies(requesterId: string): Promise<Cookie> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    return firstValueFrom(
      this.post<Cookie>(`${this.cookiesURL}/credid/value`, { requesterId }, options).pipe(
        catchError(() => of({} as Cookie)),
      ),
    );
  }

  setLoginIdCookies(requesterId: string, loginId: string): Observable<void> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const body = {
      requesterId,
      loginId,
    };

    return this.post<void>(`${this.cookiesURL}/loginid`, body, options).pipe(catchError(() => of(void 0)));
  }

  deleteConnectionCookies(requesterId: string): Observable<void> {
    const options = {
      withCredentials: true,
    };

    return this.delete<void>(`${this.cookiesURL}/connection/${requesterId}`, options);
  }
}
