import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { AppStore } from '../app.store';

@Injectable()
export class DomService {
  window: Window = window;

  document: Document = window.document;

  private storage: Storage = new StorageService();

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  private messagesCallbacks: { [key: string]: (params: any) => void } = {};

  constructor(private appStore: AppStore) {
    try {
      this.storage = this.window.localStorage;
    } catch {}

    /* istanbul ignore next */
    this.window.addEventListener('message', (message) => this.onPostMessage(message), false);
  }

  closeWindow(): void {
    if (this.isWebview()) {
      this.window.history.back();
      return;
    }

    this.window.close();
  }

  timeout(callBack: () => void, timeout?: number): void {
    this.window.setTimeout(callBack, timeout);
  }

  isFromDesktop(): boolean {
    return ['', this.window.location.href].includes(this.document.referrer);
  }

  async generateKeyPair(exportable = false): Promise<CryptoKeyPair> {
    return this.window.crypto.subtle.generateKey(
      {
        name: 'RSASSA-PKCS1-v1_5',
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: { name: 'SHA-256' },
      },
      exportable,
      ['sign'],
    );
  }

  async exportPublicKey(keyPair: CryptoKeyPair): Promise<string> {
    const publicKey = await this.window.crypto.subtle.exportKey('spki', keyPair.publicKey as CryptoKey);

    const exportedPublicKey = btoa(String.fromCharCode(...new Uint8Array(publicKey)));

    return `-----BEGIN PUBLIC KEY-----\n${exportedPublicKey}\n-----END PUBLIC KEY-----`;
  }

  async exportPrivateKey(keyPair: CryptoKeyPair): Promise<string> {
    const privateKey = await this.window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey as CryptoKey);

    const exportedPrivateKey = btoa(String.fromCharCode(...new Uint8Array(privateKey)));

    return `-----BEGIN RSA PRIVATE KEY-----\n${exportedPrivateKey}\n-----END RSA PRIVATE KEY-----`;
  }

  async getSha1Base64(str: string): Promise<string> {
    const buf = await this.window.crypto.subtle.digest('SHA-1', new TextEncoder().encode(str));

    return btoa(String.fromCharCode(...new Uint8Array(buf)))
      .split('=')[0]
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  async getSha256Base64(str: string): Promise<string> {
    const buf = await this.window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(str));

    return btoa(String.fromCharCode(...new Uint8Array(buf)))
      .split('=')[0]
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  getSecret(keys: CryptoKeyPair, data: any): PromiseLike<ArrayBuffer> {
    return this.window.crypto.subtle.sign('RSASSA-PKCS1-v1_5', keys.privateKey as CryptoKey, data);
  }

  async validateSecret(pubKey: ArrayBuffer, secret: ArrayBuffer, data: ArrayBuffer): Promise<boolean> {
    const key = await this.window.crypto.subtle.importKey(
      'spki',
      pubKey,
      {
        name: 'RSASSA-PKCS1-v1_5',
        hash: 'SHA-256',
      },
      false,
      ['verify'],
    );

    return this.window.crypto.subtle.verify('RSASSA-PKCS1-v1_5', key, secret, data);
  }

  getDateNow(): number {
    // @ts-ignore
    return this.window.Date.now();
  }

  getItem<T>(key: string): T {
    let value;

    try {
      const rawValue = this.storage.getItem(key);
      value = rawValue !== null ? JSON.parse(rawValue) : undefined;
    } catch {
      value = undefined;
    }

    return value;
  }

  setItem<T>(key: string, value: T): void {
    return this.storage.setItem(key, JSON.stringify(value));
  }

  idbStore(callback: (store: IDBObjectStore) => void): void {
    // @ts-ignore
    const indexedDB =
      this.window.indexedDB ||
      // @ts-ignore
      this.window.mozIndexedDB ||
      // @ts-ignore
      this.window.webkitIndexedDB ||
      // @ts-ignore
      this.window.msIndexedDB ||
      // @ts-ignore
      this.window.shimIndexedDB;

    const open = indexedDB.open('Ownid', 1);

    open.onupgradeneeded = function onupgradeneeded() {
      const db = open.result;
      db.createObjectStore('ownid');
    };

    open.onsuccess = function onsuccess() {
      const db = open.result;
      const tx = db.transaction('ownid', 'readwrite');
      const store = tx.objectStore('ownid');

      callback(store);

      tx.oncomplete = function oncomplete() {
        db.close();
      };
    };
  }

  postMessage(message: string): void {
    this.window.opener?.postMessage(message, '*');
  }

  onPostMessage(messageEvent: MessageEvent): void {
    try {
      const messageObject =
        (typeof messageEvent.data === 'string' ? JSON.parse(messageEvent.data) : messageEvent.data) || {};
      if (messageObject.target !== 'ownid') {
        return;
      }

      this.messagesCallbacks[messageObject.message]?.(messageObject.data);
    } catch {}
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  onMessage(action: string, callback: (param: any) => void): void {
    this.messagesCallbacks[action] = callback;
  }

  getIOSVersion(): number {
    const agent = this.window.navigator.userAgent;
    const start = agent.indexOf('OS');
    if (agent.includes('iPhone') && start > -1) {
      // @ts-ignore
      return this.window.Number(agent.slice(start + 3, start + 7).replace('_', '.'));
    }
    return 0;
  }

  isFirefox(): boolean {
    const agent = this.window.navigator.userAgent;

    return agent.includes('FxiOS');
  }

  isInstantFidoSupported(isIos: boolean, enroll: boolean, fidoTriggered: boolean): boolean {
    return (
      (!isIos || (!this.isFirefox() && this.getIOSVersion() >= 15.4 && !enroll && !fidoTriggered)) && this.hasFocus()
    );
  }

  signAlgorithm = {
    name: 'RSASSA-PKCS1-v1_5',
    hash: {
      name: 'SHA-256',
    },
    modulusLength: 2048,
    extractable: false,
    publicExponent: new Uint8Array([1, 0, 1]),
  };

  async importPublicKey(pemKey: string): Promise<CryptoKey> {
    return this.window.crypto.subtle.importKey(
      'spki',
      DomService.convertPemToBinary(pemKey),
      this.signAlgorithm,
      true,
      ['verify'],
    );
  }

  async importPrivateKey(pemKey: string): Promise<CryptoKey> {
    return this.window.crypto.subtle.importKey(
      'pkcs8',
      DomService.convertPemToBinary(pemKey),
      this.signAlgorithm,
      true,
      ['sign'],
    );
  }

  async encryptWithPassphrase(data: string, passphrase: string, vector: string): Promise<string> {
    const symKey = await this.createSymmetricKey(passphrase);
    const iv = DomService.stringToArrayBuffer(atob(vector));
    const encData = await this.window.crypto.subtle.encrypt(
      { name: 'AES-CBC', iv },
      symKey,
      DomService.stringToArrayBuffer(data),
    );
    return btoa(String.fromCharCode(...new Uint8Array(encData)));
  }

  async decryptWithPassphrase(data: string, passphrase: string, vector: string): Promise<string> {
    const symKey = await this.createSymmetricKey(passphrase);
    const iv = DomService.stringToArrayBuffer(atob(vector));
    const encData = DomService.stringToArrayBuffer(atob(data));
    const decrData = await this.window.crypto.subtle.decrypt({ name: 'AES-CBC', iv }, symKey, encData);
    return String.fromCharCode(...new Uint8Array(decrData));
  }

  getURLParam(paramName: string): string | null {
    const url = this.document.location.search.replace('?', '');
    const urlParts = url.split('&');
    const paramsList = {} as { [key: string]: string };

    for (const urlPart of urlParts) {
      const [key, value] = urlPart.split('=');
      paramsList[`$${key}`] = value;
    }

    return paramsList[`$${paramName}`] ? decodeURIComponent(paramsList[`$${paramName}`]) : null;
  }

  replaceLocation(url: string): void {
    if (url.startsWith('javascript')) {
      return;
    }

    this.document.location.replace(url);
  }

  private async createSymmetricKey(passphrase: string): Promise<CryptoKey> {
    const hashed = await this.window.crypto.subtle.digest(
      { name: 'SHA-256' },
      DomService.stringToArrayBuffer(passphrase),
    );
    return this.window.crypto.subtle.importKey('raw', hashed, { name: 'AES-CBC' }, false, ['encrypt', 'decrypt']);
  }

  createCryptoPair(publicKey: CryptoKey, privateKey: CryptoKey): CryptoKeyPair {
    return { publicKey, privateKey } as CryptoKeyPair;
  }

  private static convertPemToBinary(pem: string): Uint8Array {
    const lines = pem.split('\n');
    let encoded = '';

    for (const item of lines) {
      if (
        item.trim().length > 0 &&
        !item.includes('-BEGIN RSA PRIVATE KEY-') &&
        !item.includes('-BEGIN PUBLIC KEY-') &&
        !item.includes('-END RSA PRIVATE KEY-') &&
        !item.includes('-END PUBLIC KEY-')
      ) {
        encoded += item.trim();
      }
    }
    return DomService.stringToArrayBuffer(atob(encoded));
  }

  private static stringToArrayBuffer(byteStr: string): Uint8Array {
    const bytes = new Uint8Array(byteStr.length);
    for (let i = 0; i < byteStr.length; i++) {
      bytes[i] = byteStr.charCodeAt(i);
    }
    return bytes;
  }

  isWebviewSupportsFido(): boolean {
    return !this.isWebview() || this.getIOSVersion() >= 15.5;
  }

  isWebview(): boolean {
    const rules = [
      // if it says it's a webview, let's go with that
      'WebView',
      // iOS webview will be the same as safari but missing "Safari"
      '(iPhone|iPod|iPad)(?!.*Safari)',
      // https://developer.chrome.com/docs/multidevice/user-agent/#webview_user_agent
      'Android.*Version/[0-9].[0-9]',
      // Also, we should save the wv detected for Lollipop
      // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
      'Android.*wv',
      // old chrome android webview agent
      'Linux; U; Android',
    ];
    const webviewRegExp = new RegExp('(' + rules.join('|') + ')', 'ig');

    return webviewRegExp.test(this.window.navigator.userAgent);
  }

  getChallengeFromUrl(): string | null {
    const url = this.getURLParam('q')!;
    const decodedUrl = decodeURIComponent(url);
    const contextRegex = /.+ownid\/([\dA-Za-z\-]+)\/.+/g;
    const match = contextRegex.exec(decodedUrl);

    if (match) {
      const [, challenge] = match;
      return challenge;
    }

    return null;
  }

  hasFocus(): boolean {
    return this.window.document.hasFocus();
  }

  getHost(): string {
    return this.window.location.hostname;
  }

  base64ToUint8Array(str: string): Uint8Array {
    str = str.replace(/-/g, '+').replace(/_/g, '/').replace(/\s/g, '');
    // @ts-ignore
    return new Uint8Array(Array.prototype.map.call(atob(str), (c) => c.charCodeAt(0)));
  }

  uint8ArrayToBase64(a: Uint8Array): string {
    const base64string = btoa(String.fromCharCode(...a));
    return base64string.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  utf8ToUint8Array(str: string): Uint8Array {
    str = btoa(unescape(encodeURIComponent(str)));
    return this.base64ToUint8Array(str);
  }

  navigateAndClose(redirectURI: string | null): void {
    if (redirectURI) {
      this.replaceLocation(redirectURI);

      if (this.appStore.isIos$.getValue()) {
        return;
      }
    }

    this.closeWindow();
  }
}
