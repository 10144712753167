import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SandboxModeAlertComponent } from './sandbox-mode-alert.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SandboxModeAlertComponent],
  exports: [SandboxModeAlertComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
})
export class SandboxModeAlertModule {}
