import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService, private navController: NavController) {}

  intercept(req: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
    if (this.translateService.currentLang) {
      const headers = req.headers.append('Accept-Language', this.translateService.currentLang);
      const newReq = req.clone({ headers });
      return next.handle(newReq);
    }

    return next.handle(req).pipe(
      catchError((e) => {
        if (e.status === 500) {
          this.navController.navigateForward('/error', { animated: false, skipLocationChange: true });
        }
        return throwError(e);
      }),
    );
  }
}
