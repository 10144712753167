export class StorageService implements Storage {
  private storage: { [key: string]: string } = {};

  get length(): number {
    return Object.keys(this.storage).length;
  }

  clear(): void {
    this.storage = {};
  }

  getItem(key: string): string | null {
    return this.storage[key] || null;
  }

  removeItem(key: string): void {
    delete this.storage?.[key];
  }

  setItem(key: string, value: string): void {
    this.storage[key] = value;
  }

  key(index: number): string | null {
    return Object.keys(this.storage)[index] || null;
  }
}
