import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

export abstract class BaseRepository {
  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, options?: object): Observable<T> {
    return this.httpClient.get<T>(url, options);
  }

  post<T>(url: string, body?: unknown, options?: object): Observable<T> {
    return this.httpClient.post<T>(url, body, options);
  }

  request<T>(
    method: string,
    url: string,
    body?: unknown,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      responseType?: 'json';
      reportProgress?: boolean;
      withCredentials?: boolean;
    },
  ): Observable<T> {
    return this.httpClient.request<T>(method, url, {
      ...options,
      body,
      params: { t: `${new Date().toISOString()}`, ...options?.params },
    });
  }

  put<T>(
    url: string,
    body?: unknown,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T> {
    return this.httpClient.put<T>(url, body, options);
  }

  delete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
  ): Observable<T> {
    return this.httpClient.delete<T>(url, options);
  }
}
