import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from '@services/logger.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private loggerService: LoggerService) {}

  handleError(error: Error): void {
    this.loggerService.logError(
      error.message || error.toString(),
      { error: error.stack || error },
      'GlobalErrorHandler',
    );
  }
}
