import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { DomService } from '@services/dom.service';
import { AppStore } from '../app.store';
import { ICommand } from '../pages/i-command';
import { IConnections } from '../shared/i-connection';

const CONNECTIONS_KEY = 'connections';

@Injectable()
export class StoreFillInCommand implements ICommand {
  constructor(private platform: Platform, private appStore: AppStore, private domService: DomService) {}

  async execute(): Promise<void> {
    const connections = this.domService.getItem<IConnections>(CONNECTIONS_KEY) || {};

    this.appStore.connections$.next(connections);
    this.appStore.connections$.subscribe((newCons) => {
      this.domService.setItem(CONNECTIONS_KEY, newCons);
    });

    this.appStore.isIos$.next(this.platform.is('ios'));

    this.appStore.iosVersion$.next(this.domService.getIOSVersion());
  }
}
